<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close', null)"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Add new Listing Agent</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4"  v-if="ui.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-bottom-0" v-else>
          <div class="row">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">First Name</label>
                <input id="fieldFirstName" v-model="formData.firstName"
                       :class="{ 'input-filled': formData.firstName !== '' }"
                       class="form-control" placeholder="First Name" type="text"/>
              </div>
            </div>
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Last Name</label>
                <input id="fieldLastName" v-model="formData.lastName"
                       :class="{ 'input-filled': formData.lastName !== '' }"
                       class="form-control" placeholder="Last Name" type="text"/>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Email</label>
                <input id="fieldEmail" v-model="formData.email"
                       :class="{ 'input-filled': formData.firstName !== '' }"
                       class="form-control" placeholder="Email" type="email"/>
              </div>
            </div>
            <div class="col-6">
              <div class="full-width form-group">
                <label class="mb-1">Phone</label>
                <input id="fieldPhone" v-model="formData.phone"
                       :class="{ 'input-filled': formData.phone !== '' }"  v-mask="'(###) ###-####'"
                       class="form-control" placeholder="Phone" type="text"/>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="full-width form-check">
                <div>
                  <input id="fieldIsTC" v-model="formData.isTC" class="form-check-input" type="checkbox"/>
                </div>
                <label class="mb-1" for="fieldIsTC">isTC</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start"  v-if="!ui.loading">
        <base-button title="Submit" action="secondary-default" type="submit" @click-btn="storeRealtor" :loading="ui.btnLoading" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close', null)" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'NewListingAgentModal',
  components: { BaseButton },
  data() {
    return {
      ui: {
        loading: false,
        btnLoading: false
      },
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isTC: false
      },
    }
  },
  methods: {
    storeRealtor() {
      this.ui.btnLoading = true;
      let formData = new FormData();
      formData.append('firstName', this.formData.firstName);
      formData.append('lastName', this.formData.lastName);
      formData.append('email', this.formData.email);
      formData.append('phone', this.formData.phone);
      formData.append('isTC', this.formData.isTC);
      this.$http.post('/api/v1/listing-agents/new', formData)
        .then((response) => {
          this.formData.firstName = '';
          this.formData.lastName = '';
          this.formData.email = '';
          this.formData.phone = '';
          this.formData.isTC = '';
          this.$emit('close', response.data.realtor);
          this.ui.btnLoading = false;
        })
        .catch(() => {
          this.ui.btnLoading = false;
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-check {
  display: flex;
  align-items: center;
  margin: 0;
  input.form-check-input {
    height: 18px;
    width: 18px;
    max-width: 18px;
    margin-right: 10px;
  }
  label {
    margin-bottom: 0 !important;
  }
}
.modal {
  .modal-block {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: initial;
  }
  .modal-body {
    padding: 30px;
    margin-left: 1rem;
    .r-2 {
      right: 1rem !important;
    }
  }
  .modal-header {
    position: relative;
  }
  .modal-footer {
    position: absolute;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px!important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none!important;
  }
}
::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    width: 100%;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
</style>
